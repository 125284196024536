import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'

const BookMark = ({ bookmarked, onClick }) => {
  const onMouseLeave = () => {
    document.getElementById('toast').style.display = 'none'
  }

  const showToast = () => {
    var content = document.getElementById('toast')

    content.style.display = 'inline'
  }

  return (
    <span>
      {bookmarked ? (
        <span id="toast">Added to bookmark</span>
      ) : (
        <span id="toast">Bookmark this Property?</span>
      )}
      {bookmarked ? (
        <FontAwesomeIcon
          icon={solid('star')}
          onClick={onClick}
          onMouseOver={showToast}
          onMouseLeave={onMouseLeave}
          id="bookmark"
        />
      ) : (
        <FontAwesomeIcon
          icon={regular('star')}
          onClick={onClick}
          onMouseOver={showToast}
          onMouseLeave={onMouseLeave}
          id="bookmark"
        />
      )}
    </span>
  )
}

export default BookMark
