import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import logo from '../images/logo.png'

class Header extends Component {
  state = {
    loggedIn: false,
  }

  componentDidMount() {
    const token = localStorage.getItem('token')
    if (token === null) {
      this.setState({ loggedIn: false })
    } else {
      this.setState({ loggedIn: true })
    }
  }

  setLoggedIn = () => {
    const token = localStorage.getItem('token')
    if (token === null) {
      this.setState({ loggedIn: false })
    } else {
      this.setState({ loggedIn: true })
    }
  }

  logout = () => {
    localStorage.removeItem('token')
    this.setState({ loggedIn: false })
  }
  render() {
    return (
      <header className="fixed-top bg-light" onMouseOver={this.setLoggedIn}>
        <nav
          className="navbar navbar-expand-md navbar-light bg-white"
          aria-label="Fourth navbar example"
        >
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarsExample04"
              aria-controls="navbarsExample04"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarsExample04">
              <ul className="navbar-nav me-auto mb-2 mb-md-0">
                <li className="nav-item">
                  <Link to="/" className="nav-link active" aria-current="page">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/properties" className="nav-link">
                    Properties
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/about-us" className="nav-link">
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact-us" className="nav-link">
                    Contact Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/terms-and-conditions" className="nav-link">
                    Terms And Conditons
                  </Link>
                </li>
              </ul>
              {this.state.loggedIn ? (
                <button onClick={this.logout} className="btn btn-info m-2">
                  Logout
                </button>
              ) : (
                <span>
                  <Link to="/signin" className="btn btn-primary">
                    Sign In
                  </Link>
                  <Link to="/register" className="btn btn-info m-2">
                    Register
                  </Link>
                </span>
              )}
            </div>
          </div>
        </nav>
      </header>
    )
  }
}

export default Header
