export const p1 = "Regale Homes: Your Partner in Finding Your Dream Home"

export const p2 = "Are you tired of endlessly searching for the perfect home ? Look no further than Regale Homes, your trusted partner in the real estate industry.With years of experience and a commitment to personalized service, we're here to help you find your dream home"

export const p3 = "At Regale Homes, we understand that buying a home is one of the biggest decisions you'll ever make. That's why we take the time to get to know our clients and understand their unique needs and preferences.Whether you're a first-time homebuyer or a seasoned investor, we're here to guide you through the process and help you make informed decisions every step of the way."

export const p4 = "Our team of licensed real estate agents has extensive knowledge of the local market and can provide valuable insights into the neighborhoods and properties that best suit your lifestyle and budget.We'll work with you to identify your must-haves and deal-breakers, and then use our expertise to narrow down the options and find the homes that meet your criteria."

export const p5 = "But our commitment to personalized service doesn't stop there. We understand that buying a home can be a stressful and overwhelming process, which is why we're here to provide support and guidance every step of the way.From negotiating offers to coordinating inspections and closing, we'll be with you every step of the way to ensure a smooth and successful transaction."

export const p6 = "At Regale Homes, we're also committed to using the latest technology to make the home-buying process as convenient and efficient as possible. Our website features a comprehensive property search tool that allows you to filter by location, price, and other key criteria. You can also save your favorite properties and receive alerts when new homes that meet your criteria become available."

export const p7 = "But our commitment to technology doesn't stop there. We also use cutting-edge tools and software to streamline the transaction process and ensure that everything is done quickly and accurately. From electronic signatures to virtual tours, we're always looking for ways to make the home - buying process more convenient and accessible for our clients."

export const p8 = "At Regale Homes, we're not just in the business of buying and selling homes"