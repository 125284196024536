import React from 'react'

const PrivacyPolicyText = () => {
  return (
    <section id="terms-and-condition" className="terms">
      <p className="lead">
        Regale Homes is committed to protecting the privacy and security of our
        clients, employees, and website visitors. This Privacy Policy outlines
        how we collect, use, and protect your personal information. By using our
        services, you consent to our collection, use, and disclosure of your
        personal information as described in this policy.
      </p>
      <h2 className="h4 no-border">Collection of Personal Information:</h2>
      <p>
        We collect personal information from our clients, employees, and website
        visitors. This information may include your name, address, phone number,
        email address, and other contact information. We may also collect
        information about your property, financial information, and other
        information necessary to provide our services.
      </p>
      <h2 className="h4 no-border">Use of Personal Information:</h2>
      <p>
        We use your personal information to provide our services, including
        buying and selling properties, property management, and other related
        services. We may also use your information to communicate with you,
        provide customer support, and improve our services.
      </p>
      <h2 className="h4 no-border">Disclosure of Personal Information:</h2>
      <p>
        We may disclose your personal information to third-party service
        providers, such as real estate agents, property managers, and financial
        institutions, to provide our services. We may also disclose your
        information to government agencies or law enforcement officials as
        required by law.
      </p>
      <h2 className="h4 no-border">Protection of Personal Information:</h2>
      <p>
        We take reasonable measures to protect your personal information from
        unauthorized access, use, or disclosure. We use encryption, firewalls,
        and other security measures to protect your information. However, no
        security system is perfect, and we cannot guarantee the security of your
        information.
      </p>
      <h2 className="h4 no-border">Retention of Personal Information:</h2>
      <p>
        We retain your personal information for as long as necessary to provide
        our services and comply with legal obligations. We may also retain your
        information for a reasonable period of time after you cease to use our
        services.
      </p>
      <h2 className="h4 no-border">Access to Personal Information:</h2>
      <p>
        You have the right to access, correct, or delete your personal
        information. You may also request that we restrict the processing of
        your information or transfer your information to another service
        provider. To exercise these rights, please contact us at &nbsp;
        <a href="mailto:info@regalehomes.com" className="no-deco">
          info@regalehomes.com
        </a>
        .
      </p>
      <h2 className="h4 no-border">Cookies:</h2>
      <p>
        We use cookies and other tracking technologies to improve our website
        and services. Cookies are small data files that are placed on your
        device when you visit our website. You may disable cookies in your
        browser settings, but this may limit your ability to use our website and
        services.
      </p>
      <h2 className="h4 no-border">Third-Party Websites:</h2>
      <p>
        Our website may contain links to third-party websites. We are not
        responsible for the privacy practices or cookie policy on the third
        party Website.
      </p>
      <h2 className="h4 no-border">Children’s Privacy:</h2>
      <p>
        Our services are not intended for children under the age of 18. We do
        not knowingly collect personal information from children under the age
        of 18. If we become aware that we have collected personal information
        from a child under the age of 18, we will take steps to delete the
        information as soon as possible.
      </p>
      <h2 className="h4 no-border">Marketing Communications:</h2>
      <p>
        We may use your personal information to send you marketing
        communications about our services, promotions, and other information
        that may be of interest to you. You may opt-out of receiving these
        communications at any time by following the instructions in the
        communication or contacting us at &nbsp;
        <a href="mailto:info@regalehomes.com" className="no-deco">
          info@regalehomes.com
        </a>
        .
      </p>
      <h2 className="h4 no-border">Social Media:</h2>
      <p>
        We may use social media platforms to communicate with our clients and
        promote our services. These platforms may collect personal information
        about you, such as your name, profile picture, and other information you
        choose to share. We are not responsible for the privacy practices of
        these platforms. We encourage you to review the privacy policies of
        these platforms before providing any personal information.
      </p>
      <h2 className="h4 no-border">International Data Transfers:</h2>
      <p>
        We may transfer your personal information to third-party service
        providers located outside of your country of residence. These transfers
        may be necessary to provide our services or comply with legal
        obligations. We take steps to ensure that your personal information is
        protected in accordance with applicable laws and regulations.
      </p>
      <h2 className="h4 no-border">California Privacy Rights:</h2>
      <p>
        If you are a California resident, you have certain rights under the
        California Consumer Privacy Act (CCPA). These rights include the right
        to know what personal information we have collected about you, the right
        to request that we delete your personal information, and the right to
        opt-out of the sale of your personal information. To exercise these
        rights, please contact us at &nbsp;
        <a href="mailto:info@regalehomes.com" className="no-deco">
          info@regalehomes.com
        </a>
        .
      </p>
      <h2 className="h4 no-border">European Union Data Protection:</h2>
      If you are a resident of the European Union, you have certain rights under
      the General Data Protection Regulation (GDPR). These rights include the
      right to access, correct, or delete your personal information, the right
      to restrict the processing of your information, and the right to data
      portability. To exercise these rights, please contact us at &nbsp;
      <a href="mailto:info@regalehomes.com" className="no-deco">
        info@regalehomes.com
      </a>
      .
      <br />
      <br />
      <hr />
    </section>
  )
}

export default PrivacyPolicyText
