import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const NewProperties = ({ properties }) => {
  const newProperties = properties.slice(0, 4)
  return (
    <section id="new-properties" className="block mt-3">
      <div className="container">
        <header className="section-title">
          <h2 className="d-flex m-3 mt-3 mb-3">New Properties for You</h2>
        </header>
        <div className="row">
          {newProperties.map((property) => (
            <div className="col-md-3 col-sm-6" key={property.id}>
              <div className="property">
                <Link to={`/properties/${property.id}`}>
                  <div className="property-image">
                    <img alt="" src={property.image_3} />
                  </div>
                  <div className="overlay">
                    <div className="info">
                      <div className="tag price">${property.price}</div>
                      <h3 className="h6">{property.name}</h3>
                      <figure>
                        {property.city}, {property.district}
                      </figure>
                    </div>
                    <ul className="additional-info">
                      <li>
                        <header>Area:</header>
                        <figure>
                          {property.area}m<sup>2</sup>
                        </figure>
                      </li>
                      <li>
                        <header>Beds:</header>
                        <figure>{property.no_of_beds}</figure>
                      </li>
                      <li>
                        <header>Baths:</header>
                        <figure>{property.no_of_baths}</figure>
                      </li>
                      <li>
                        <header>Garages:</header>
                        <figure>{property.no_of_garages}</figure>
                      </li>
                    </ul>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
        <div className="row">
          {newProperties.map((property) => (
            <div className="col-md-3 col-sm-6" key={property.id}>
              <div className="property">
                <Link to={`/properties/${property.id}`}>
                  <div className="property-image">
                    <img alt="" src={property.image_3} />
                  </div>
                  <div className="overlay">
                    <div className="info">
                      <div className="tag price">${property.price}</div>
                      <h3 className="h6">{property.name}</h3>
                      <figure>
                        {property.city}, {property.district}
                      </figure>
                    </div>
                    <ul className="additional-info">
                      <li>
                        <header>Area:</header>
                        <figure>
                          {property.area}m<sup>2</sup>
                        </figure>
                      </li>
                      <li>
                        <header>Beds:</header>
                        <figure>{property.no_of_beds}</figure>
                      </li>
                      <li>
                        <header>Baths:</header>
                        <figure>{property.no_of_baths}</figure>
                      </li>
                      <li>
                        <header>Garages:</header>
                        <figure>{property.no_of_garages}</figure>
                      </li>
                    </ul>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
        <Link to="properties" className="call-to-action float-right">
          All Properties
          <FontAwesomeIcon icon={solid('chevron-right')} className="bi" />
        </Link>
      </div>
    </section>
  )
}

export default NewProperties
