import React from 'react'
import { Link } from 'react-router-dom'

const SimilarProperties = ({ properties, property }) => {
  const currentProperty = property
  const similarProperties = properties.filter(
    (property) => property.property_type === currentProperty.property_type,
  )
  const topThreeSimilar = similarProperties.slice(0, 3)
  return (
    <section id="similar-properties">
      <header>
        <h2 className="no-border">Similar Properties</h2>
      </header>
      <div className="row">
        {topThreeSimilar.map((property) => (
          <div className="col-md-4 col-sm-6" key={property.id}>
            <div className="property">
              <Link to="property-detail.html">
                <div className="property-image">
                  <img alt="" src={property.image_3} />
                </div>
                <div className="overlay">
                  <div className="info">
                    <div className="tag price">${property.price}</div>
                    <h3 className="h6">{property.name}</h3>
                    <figure>
                      {property.city}, {property.district}
                    </figure>
                  </div>
                  <ul className="additional-info">
                    <li>
                      <header>Area:</header>
                      <figure>
                        {property.area}m<sup>2</sup>
                      </figure>
                    </li>
                    <li>
                      <header>Beds:</header>
                      <figure>{property.no_of_beds}</figure>
                    </li>
                    <li>
                      <header>Baths:</header>
                      <figure>{property.no_of_baths}</figure>
                    </li>
                    <li>
                      <header>Garages:</header>
                      <figure>{property.no_of_garages}</figure>
                    </li>
                  </ul>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default SimilarProperties
