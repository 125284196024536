import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import PropertiesSide from './PropertiesSide'
import Rating from './common/Rating'
import BookMark from './common/BookMark'
import '../services/popup'
import SimilarProperties from './SimilarProperties'
import Comments from './common/Comments'
import api from '../config.json'

class PropertyDetail extends Component {
  state = {
    properties: [],
    property: [],
    agent: [],
    bookmarked: false,
    similarProperties: [],
  }

  async componentDidMount() {
    let webApiUrl = api.api
    const { data: properties } = await axios.get(webApiUrl + '/properties/')
    this.setState({ properties })

    // const token = localStorage.getItem('token')
    // const user = await axios.get(`http://127.0.0.1:8000/get-user`, {
    //   headers: { Authorization: `Bearer ${token}` },
    // })
    // console.log(user)

    const id = this.props.match.params.id
    const { data: property } = await axios.get(
      webApiUrl + '/properties/' + id + '/',
    )
    this.setState({ property })

    const agentId = property.agent

    const { data: agent } = await axios.get(webApiUrl + '/agents/' + agentId)
    this.setState({ agent })
  }

  handleBookmark = () => {
    const bookmarked = this.state.bookmarked

    bookmarked
      ? this.setState({ bookmarked: false })
      : this.setState({ bookmarked: true })
  }

  render() {
    const { property, properties, agent, bookmarked } = this.state
    return (
      <div className="container">
        <div id="page-content">
          <div className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/" className="no-deco">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active">Property Detail</li>
              </ol>
            </nav>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-9 col-sm-9">
                <section id="property-detail">
                  <header className="property-title">
                    <h1>{property.name}</h1>
                    <figure>
                      {property.city}, {property.district}
                    </figure>
                    <span className="actions">
                      <BookMark
                        bookmarked={bookmarked}
                        onClick={this.handleBookmark}
                        onMouseOver={this.showToast}
                      />
                    </span>
                  </header>
                  <div
                    id="myCarousel"
                    className="carousel slide h-500"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-indicators">
                      <button
                        type="button"
                        data-bs-target="#myCarousel"
                        data-bs-slide-to="0"
                        className="active"
                        aria-current="true"
                        aria-label="Slide 1"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#myCarousel"
                        data-bs-slide-to="1"
                        aria-label="Slide 2"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#myCarousel"
                        data-bs-slide-to="2"
                        aria-label="Slide 3"
                      ></button>
                    </div>
                    <div className="carousel-inner" id="carousel-inner">
                      <div id="slider" className="carousel-item active">
                        <a href={property.image_1} className="image-popup">
                          <img
                            className="bd-placeholder-img carousel-bg-image h-500"
                            width="100%"
                            src={property.image_1}
                            alt=""
                            aria-hidden="true"
                            preserveAspectRatio="xMidYMid slice"
                            focusable="false"
                          />
                        </a>
                      </div>
                      <div id="slider" className="carousel-item">
                        <a href={property.image_2} className="image-popup">
                          <img
                            className="bd-placeholder-img carousel-bg-image h-500"
                            width="100%"
                            src={property.image_2}
                            alt=""
                            aria-hidden="true"
                            preserveAspectRatio="xMidYMid slice"
                            focusable="false"
                          />
                        </a>
                      </div>
                      <div id="slider" className="carousel-item">
                        <a href={property.image_2} className="image-popup">
                          <img
                            className="bd-placeholder-img carousel-bg-image h-500"
                            width="100%"
                            src={property.image_1}
                            alt=""
                            aria-hidden="true"
                            preserveAspectRatio="xMidYMid slice"
                            focusable="false"
                          />
                        </a>
                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#myCarousel"
                        data-bs-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#myCarousel"
                        data-bs-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-sm-12">
                      <section id="quick-summary" className="clearfix">
                        <header>
                          <h2>Quick Summary</h2>
                        </header>
                        <dl>
                          <dt>Location</dt>
                          <dd>{property.location}</dd>
                          <dt>Price</dt>
                          <dd>
                            <span className="tag price">${property.price}</span>
                          </dd>
                          <dt>Property Type:</dt>
                          <dd>{property.type === 'H' ? 'House' : 'Hotel'}</dd>
                          <dt>Status:</dt>
                          <dd>{property.sold === true ? 'Sold' : 'Sale'}</dd>
                          <dt>Area:</dt>
                          <dd>
                            {property.area} m<sup>2</sup>
                          </dd>
                          <dt>Beds:</dt>
                          <dd>{property.no_of_beds}</dd>
                          <dt>Baths:</dt>
                          <dd>{property.no_of_baths}</dd>
                          <dt>Garages:</dt>
                          <dd>{property.no_of_garages}</dd>
                          <dt>Rating:</dt>
                          <dd>
                            <Rating dataScore={property.rating} />
                          </dd>
                        </dl>
                      </section>
                    </div>
                    <div className="col-md-8 col-sm-12">
                      <section id="description">
                        <header>
                          <h2>Property Description</h2>
                        </header>
                        <p>{property.description}</p>
                      </section>
                      {/* <section id="property-features">
                        <header>
                          <h2>Property Description</h2>
                        </header>
                        <ul className="list-unstyled property-features-list">
                          <li>Sauna</li>
                          <li>Fireplace or fire pit</li>
                          <li>Outdoor Kitchen</li>
                          <li>Tennis Courts</li>
                          <li>Trees and Landscaping</li>
                          <li>Sun Room</li>
                          <li>Family Room</li>
                          <li>Concrete Flooring</li>
                        </ul>
                      </section> */}
                      <section id="property-map">
                        <header>
                          <h2>Map</h2>
                        </header>
                        <div className="property-detail-map-wrapper">
                          <div
                            className="property-detail-map"
                            id="property-detail-map"
                          ></div>
                        </div>
                      </section>
                    </div>
                    <div className="col-md-12 col-sm-12">
                      <section id="contact-agent">
                        <header>
                          <h2>Contact Agent</h2>
                        </header>
                        <div className="row">
                          <section className="agent-form">
                            <div className="col-md-7 col-sm-12">
                              <aside className="agent-info clearfix d-flex">
                                <figure>
                                  <Link
                                    to="agent-detail.html"
                                    className="no-deco"
                                  >
                                    <img alt="" src={agent.image} />
                                  </Link>
                                </figure>
                                <div className="agent-contact-info">
                                  <h4>
                                    {agent.first_name} {agent.last_name}
                                  </h4>
                                  <p>{agent.about}</p>
                                  <dl>
                                    <dt>Phone:</dt>
                                    <dd>{agent.phone}</dd>
                                    <dt>Email:</dt>
                                    <dd>
                                      <Link
                                        to={`mailto:${agent.email}`}
                                        className="no-deco text-right"
                                      >
                                        {agent.email}
                                      </Link>
                                    </dd>
                                    <dt>Instagram:</dt>
                                    <dd>
                                      <Link
                                        to={`https://instagram.com/${agent.instagram}/`}
                                        className="no-deco"
                                      >
                                        @{agent.instagram}
                                      </Link>
                                    </dd>
                                  </dl>
                                  <hr />
                                  <Link
                                    to="agent-detail.html"
                                    className="call-to-action"
                                  >
                                    Full Profile
                                    <FontAwesomeIcon
                                      icon={solid('chevron-right')}
                                      className="bi"
                                    />
                                  </Link>
                                </div>
                              </aside>
                            </div>
                            <div className="col-md-5 col-sm-12">
                              <div className="agent-form">
                                <form
                                  id="form-contact-agent"
                                  method="post"
                                  className="clearfix"
                                >
                                  <div className="form-group">
                                    <label htmlFor="form-contact-agent-name">
                                      Your Name<em>*</em>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="form-contact-agent-name"
                                      name="name"
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="form-contact-agent-email">
                                      Your Email<em>*</em>
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      id="form-contact-agent-email"
                                      name="email"
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="form-contact-agent-message">
                                      Your Message<em>*</em>
                                    </label>
                                    <textarea
                                      className="form-control mb-2"
                                      id="form-contact-agent-message"
                                      rows="4"
                                      name="message"
                                      required
                                    ></textarea>
                                  </div>
                                  <div className="form-group">
                                    <button
                                      type="submit"
                                      className="btn btn-success"
                                      id="form-contact-agent-submit"
                                    >
                                      Send a Message
                                    </button>
                                  </div>
                                  <div id="form-contact-agent-status"></div>
                                </form>
                              </div>
                            </div>
                          </section>
                        </div>
                      </section>
                      <hr className="thick" />
                      <SimilarProperties
                        properties={properties}
                        property={property}
                      />
                      <hr className="thick" />
                      <Comments id={this.props.match.params.id} />
                    </div>
                  </div>
                </section>
              </div>
              <PropertiesSide properties={properties} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PropertyDetail
