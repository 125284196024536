import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import 'jquery/dist/jquery';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/react-fontawesome/index';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import SignIn from './components/Login';
import Register from './components/Register';
import Properties from './components/Properties';
import PropertyDetail from './components/PropertyDetail';
import AboutUs from './components/AboutUs';
import Terms from './components/Terms';
import Contact from './components/Contact';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
  return (
    <React.Fragment>
      {/* <Audio
        height="80"
        width="80"
        radius="9"
        color="green"
        ariaLabel="loading"
        wrapperStyle
        wrapperClass
      /> */}
      <Header />
      <div className='wrapper'>
        <Switch>
          <Route path='/signin' component={SignIn} />
          <Route path='/register' component={Register} />
          <Route exact path='/properties' component={Properties} />
          <Route path='/properties/:id' component={PropertyDetail} />
          <Route path='/contact-us' component={Contact} />
          <Route path='/about-us' component={AboutUs} />
          <Route path="/terms-and-conditions" component={Terms} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path='/' component={HomePage} />
          <Redirect to='/not-found' />
        </Switch>

        <Footer />
      </div>
    </React.Fragment>
  );
}

export default App;
