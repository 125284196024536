import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { p1, p2, p3, p4, p5, p6, p7, p8 } from './texts/aboutus'
import image from '../images/sine-wave.png'
import bgImage from '../images/about-us-bg.jpg'
import client01 from '../images/client-01.jpg'
import api from '../config.json'

class AboutUs extends Component {
  state = {
    agents: [],
    ceo: [],
  }

  async componentDidMount() {
    let webApiUrl = api.api
    const { data: agents } = await axios.get(webApiUrl + 'agents/')
    this.setState({ agents })
    const ceo = agents[0]
    this.setState({ ceo })
  }
  render() {
    const { ceo } = this.state
    return (
      <React.Fragment>
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/" className="no-deco">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active">About Us</li>
            </ol>
          </nav>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <section id="about-us">
                <header>
                  <h2>About Us</h2>
                </header>
                <section id="paragraphs">
                  <p className="lead">{p1}</p>
                  <p>{p2}</p>
                  <p>{p3}</p>
                  <p>{p4}</p>
                  <p>{p5}</p>
                  <p>{p6}</p>
                  <p>{p7}</p>
                  <p>{p8}</p>
                </section>
                <div className="divider-image center">
                  <img alt="" src={image} />
                </div>
                <section id="our-team">
                  <header className="center">
                    <h2 className="no-border">Our Team</h2>
                  </header>
                  <section id="ceo-section" className="center">
                    <hr className="divider" />
                    <div key={ceo.id}>
                      <Link to="agent-detail.html" className="image mt-3">
                        <img alt="" src={ceo.image} />
                      </Link>
                      <Link to={`agents/${ceo.id}`} className="no-deco">
                        <h3>
                          {ceo.first_name} {ceo.last_name}
                        </h3>
                      </Link>
                      <figure className="subtitle">Company CEO</figure>
                      <div className="background-image">
                        <img alt="" src={bgImage} />
                      </div>
                    </div>
                  </section>
                  <div className="row justify-content-center mt-0 mb-0">
                    <div className="col-md-offset-1 col-md-5 col-sm-offset-1 col-sm-5">
                      <div className="member">
                        <Link to="agent-detail.html" className="image">
                          <img alt="" src={client01} />
                        </Link>
                        <div className="tag">Top Agent</div>
                        <div className="wrapper">
                          <Link to="agent-detail.html" className="no-deco">
                            <h3>Carolyn Sloan</h3>
                          </Link>
                          <figure className="subtitle">Agent</figure>
                          <dl>
                            <dt>Phone:</dt>
                            <dd>(123) 456 789</dd>
                            <dt>Email:</dt>
                            <dd>
                              <Link to="mailto:#" className="no-deco">
                                john.doe@example.com
                              </Link>
                            </dd>
                            <dt>Skype:</dt>
                            <dd>john.doe</dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-5">
                      <div className="member">
                        <Link to="agent-detail.html" className="image">
                          <img alt="" src={client01} />
                        </Link>
                        <div className="wrapper">
                          <Link to="agent-detail.html" className="no-deco">
                            <h3>Erika Green</h3>
                          </Link>
                          <figure className="subtitle">Support</figure>
                          <dl>
                            <dt>Phone:</dt>
                            <dd>(123) 456 789</dd>
                            <dt>Email:</dt>
                            <dd>
                              <Link to="mailto:#" className="no-deco">
                                john.doe@example.com
                              </Link>
                            </dd>
                            <dt>Skype:</dt>
                            <dd>john.doe</dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default AboutUs
