import React from 'react'
import _ from 'lodash'

const Pagination = ({ itemsCount, pageSize, currentPage, onPageChange }) => {
  const pagesCount = Math.ceil(itemsCount / pageSize)
  if (pagesCount === 1) return null
  const pages = _.range(1, pagesCount + 1)

  return (
    <div className="text-center">
      <ul className="pagination">
        {pages.map((page) => (
          <li key={page} className={page === currentPage ? 'active' : ''}>
            <span onClick={() => onPageChange(page)} className="no-deco">
              {page}
            </span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Pagination
