import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import PropertiesSide from './PropertiesSide'
import Pagination from './common/Pagination'
import { paginate } from './../utils/paginate'
import api from '../config.json'

class Properties extends Component {
  state = {
    properties: [],
    no_of_properties: '',
    pageSize: 10,
    currentPage: 1,
  }

  async componentDidMount() {
    let webApiUrl = api.api + '/properties/'
    const { data: properties } = await axios.get(webApiUrl)
    this.setState({ properties })
    const no_of_properties = properties.length
    this.setState({ no_of_properties })
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page })
  }

  render() {
    const { properties, no_of_properties, pageSize, currentPage } = this.state

    const currentProperties = paginate(properties, currentPage, pageSize)
    return (
      <React.Fragment>
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/" className="no-deco">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active">Property Listing</li>
            </ol>
          </nav>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-sm-9">
              <section id="results">
                <header className="page-title">
                  <h1>Properties Listing</h1>
                </header>
                <hr />
                <section id="search-filter">
                  <figure>
                    <h3 className="d-inline-flex">
                      <FontAwesomeIcon
                        icon={solid('search')}
                        className="bi blue-search"
                      />
                      Search Results:
                    </h3>
                    <span className="search-count">{no_of_properties}</span>
                    <div className="sorting">
                      <div className="form-group">
                        <select
                          name="sorting"
                          className="form-select form-select-md"
                        >
                          <option value="">Sort By</option>
                          <option value="1">Lowest price first</option>
                          <option value="2">Highest price first</option>
                          <option value="3">Date added</option>
                        </select>
                      </div>
                    </div>
                  </figure>
                </section>
                <section id="properties" className="">
                  <div className="display-lines">
                    {currentProperties.map((property) => (
                      <div className="property d-flex" key={property.id}>
                        {property.property_type === 'H' ? (
                          <figure className="type" title="House">
                            <FontAwesomeIcon icon={solid('house-chimney')} />
                          </figure>
                        ) : (
                          <figure className="type" title="Hotel">
                            <FontAwesomeIcon icon={solid('hotel')} />
                          </figure>
                        )}
                        <div className="property-image">
                          {property.sold ? (
                            <figure className="ribbon">Sold</figure>
                          ) : (
                            <figure className="tag status">For Sale</figure>
                          )}
                          <Link to={`/properties/${property.id}`}>
                            <img alt="" src={property.image_2} />
                          </Link>
                        </div>
                        <div className="info">
                          <header>
                            <Link
                              to={`/properties/${property.id}`}
                              className="no-deco"
                            >
                              <h3>{property.name}</h3>
                            </Link>
                            <figure>
                              {property.city}, {property.district}
                            </figure>
                          </header>
                          <div className="tag price">${property.price}</div>
                          <aside>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Cras et dui vestibulum, bibendum purus sit
                              amet, vulputate mauris. Ut adipiscing gravida
                              tincidunt...
                            </p>
                            <dl>
                              <dt>Status:</dt>
                              <dd>Sale</dd>
                              <dt>Area:</dt>
                              <dd>
                                {property.area} m<sup>2</sup>
                              </dd>
                              <dt>Beds:</dt>
                              <dd>{property.no_of_beds}</dd>
                              <dt>Baths:</dt>
                              <dd>{property.no_of_baths}</dd>
                            </dl>
                          </aside>
                          <Link
                            to={`/properties/${property.id}`}
                            className="call-to-action"
                          >
                            Read More
                            <FontAwesomeIcon
                              icon={solid('chevron-right')}
                              className="bi"
                            />
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                  <br />
                  <Pagination
                    itemsCount={no_of_properties}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onPageChange={this.handlePageChange}
                  />
                </section>
              </section>
            </div>
            <PropertiesSide properties={properties} />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Properties
