import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const Rating = ({ dataScore }) => {
  const emptyStar = 5 - dataScore

  const array = ['1', '2', '3', '4', '5']
  const arr1 = array.slice(0, dataScore)
  const arr2 = array.slice(0, emptyStar)

  return (
    <div className="rating-overall" data-score={dataScore}>
      {arr2.map((arr) => (
        <FontAwesomeIcon icon={regular('star')} className="rating" key={arr} />
      ))}
      {arr1.map((arr) => (
        <FontAwesomeIcon icon={solid('star')} className="rating" key={arr} />
      ))}
    </div>
  )
}

export default Rating
