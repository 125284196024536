import React, { Component } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

class Comments extends Component {
  state = {
    data: {
      property_id: '',
      comment: '',
    },
    comments: [],
  }

  async componentDidMount() {
    let webApiUrl = 'http://127.0.0.1:8000/api/'
    const id = parseInt(this.props.id)
    const data = { ...this.state.data }
    data.property_id = id
    this.setState({ data })
    const { data: comments } = await axios.get(
      `${webApiUrl}comments?property_id=${id}`,
    )
    this.setState({ comments })
  }

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data }
    data[input.name] = input.value
    this.setState({ data })
  }

  handleSubmit = async (e) => {
    e.preventDefault()

    const data = { ...this.state.data }
    const token = localStorage.getItem('token')
    try {
      const { data: response } = await axios.post(
        'http://127.0.0.1:8000/api/comments/',
        data,
        {
          headers: { Authorization: `Token ${token}` },
        },
      )
      localStorage.setItem('user', response.user)
      this.props.history.push('/')
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors }
        errors.non_field_errors = ex.response.data.non_field_errors
        this.setState({ errors })
      }
    }
  }

  render() {
    const { comments } = this.state
    return (
      <section id="comments">
        {comments.length > 0 ? (
          <div>
            <header>
              <h2 className="no-border">Comments</h2>
            </header>
            <ul className="comments">
              {comments.map((comment) => (
                <li className="comment" key={comment.id}>
                  <figure>
                    <FontAwesomeIcon
                      icon={solid('message')}
                      width="100"
                      className="comment-image"
                    />
                  </figure>
                  <div className="comment-wrapper">
                    <div className="name h5 float-left">{comment.user}</div>
                    <span className="date float-right">
                      <FontAwesomeIcon icon={solid('camera')} />
                      &nbsp; {comment.date_added}
                    </span>
                    <br />
                    <br />
                    <p>{comment.comment}</p>
                    <hr />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <header>
            <h2 className="no-border">No Comments Yet</h2>
          </header>
        )}
        <div className="row">
          <h3 className="h3">Leave a Comment</h3>
          <div className="float-right">
            <form>
              <div className="form-group mb-3">
                <label htmlFor="message">Message:</label>
                <textarea
                  type="text"
                  rows="4"
                  value={this.state.data.comment}
                  name="comment"
                  onChange={this.handleChange}
                  id="message"
                  className="form-control"
                ></textarea>
              </div>
              <button
                className="btn btn-primary float-right"
                onClick={this.handleSubmit}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>
    )
  }
}

export default Comments
