import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const PropertiesSide = ({ properties }) => {
  const featured = properties.slice(0, 3)
  return (
    <div className="col-md-3 col-sm-3">
      <section id="sidebar" className="sticky-xl-top mt-5">
        <aside id="featured-properties">
          <header className="page-title mt-2">
            <h3>Featured Properties</h3>
          </header>
          <hr />
          {featured.map((property) => (
            <div className="property small" key={property.id}>
              <Link to={`/properties/${property.id}`} className="no-deco">
                <div className="property-image">
                  <img alt="" src={property.image_3} />
                </div>
              </Link>
              <div className="info">
                <Link to={`/properties/${property.id}`} className="no-deco">
                  <h4>{property.name}</h4>
                </Link>
                <figure>
                  {property.city}, {property.district}
                </figure>
                <div className="tag price">${property.price}</div>
              </div>
            </div>
          ))}
        </aside>
        <aside id="our-guides">
          <header className="page-title">
            <h3>Our Guides</h3>
          </header>
          <hr />
          <Link to="#" className="universal-button no-deco">
            <figure>
              <FontAwesomeIcon icon={solid('home')} className="bi-blue" />
            </figure>
            <span>Buying Guide</span>
            <FontAwesomeIcon icon={solid('angle-right')} className="bi arrow" />
          </Link>
          <Link to="#" className="universal-button no-deco">
            <figure>
              <FontAwesomeIcon icon={solid('umbrella')} className="bi-blue" />
            </figure>
            <span>Right Insurance for You</span>
            <span className="arrow">
              <FontAwesomeIcon icon={solid('angle-right')} className="bi" />
            </span>
          </Link>
        </aside>
      </section>
    </div>
  )
}

export default PropertiesSide
