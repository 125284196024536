import React from 'react'

const TermsOfService = () => {
  return (
    <section id="terms-and-condition" className="terms">
      <p className="lead">
        Welcome to Regale Homes! We are a real estate company that provides a
        range of services to help you buy, sell, or rent properties. Before
        using our services, please read these terms and conditions carefully. By
        using our services, you agree to be bound by these terms and conditions.
      </p>
      <h2 className="h4">1. Definitions </h2>
      <p>In these terms and conditions, the following definitions apply:</p>
      <ul>
        <li>
          "Regale Homes" refers to the real estate company that provides the
          services.
        </li>
        <li>
          "Services" refers to the services provided by Regale Homes, including
          buying, selling, and renting properties.
        </li>
        <li>
          "User" refers to any person who uses the services of Regale Homes.
        </li>
        <li>
          "Property" refers to any real estate property that is bought, sold, or
          rented through the services of Regale Homes.
        </li>
      </ul>
      <h2 className="h4">2. Use of Services</h2>
      <h2 className="h5 no-border">2.1 Eligibility</h2>
      <p>
        To use our services, you must be at least 18 years old and have the
        legal capacity to enter into a contract. By using our services, you
        represent and warrant that you meet these requirements.
      </p>
      <h2 className="h5 no-border">2.2 Registration</h2>
      <p>
        To use some of our services, you may need to register an account with
        us. When you register, you must provide accurate and complete
        information. You are responsible for maintaining the confidentiality of
        your account information, including your username and password. You are
        also responsible for all activities that occur under your account. If
        you suspect that your account has been compromised, you must notify us
        immediately.
      </p>
      <h2 className="h5 no-border">2.3 Prohibited Activities</h2>
      <p>
        You agree not to use our services for any illegal or unauthorized
        purpose. You also agree not to: - Use our services to harass, abuse, or
        harm another person. - Use our services to impersonate another person or
        entity. - Use our services to upload, post, or transmit any content that
        is defamatory, obscene, or otherwise objectionable. - Use our services
        to upload, post, or transmit any content that infringes on the
        intellectual property rights of others. - Use our services to upload,
        post, or transmit any viruses, worms, or other malicious code.
      </p>
      <h2 className="h5 no-border">2.4 Termination</h2>
      <p>
        We reserve the right to terminate your access to our services at any
        time, for any reason, without notice.
      </p>
      <h2 className="h4">3. Buying Properties</h2>
      <h2 className="h5 no-border">3.1 Property Listings</h2>
      <p>
        We provide property listings on our website and other platforms. These
        listings are provided for informational purposes only and are subject to
        change without notice. We do not guarantee the accuracy or completeness
        of the listings.
      </p>
      <h2 className="h5 no-border">3.2 Property Inspections</h2>
      <p>
        Before buying a property, you should inspect it thoroughly. We recommend
        that you hire a professional inspector to inspect the property. We do
        not guarantee the condition of any property listed on our website or
        other platforms.
      </p>
      <h2 className="h5 no-border"> 3.3 Offers and Contracts </h2>
      <p>
        If you are interested in buying a property, you may make an offer to
        purchase it. We will present your offer to the seller and negotiate on
        your behalf. If your offer is accepted, we will prepare a contract for
        the sale of the property. You are responsible for reviewing the contract
        and ensuring that it meets your requirements.
      </p>
      <h2 className="h5 no-border"> 3.4 Closing</h2>
      <p>
        Once the contract is signed, we will coordinate the closing of the sale.
        You are responsible for paying all closing costs, including but not
        limited to, title insurance, taxes, and fees.
      </p>
      <h2 className="h4">4. Selling Properties</h2>
      <h2 className="h5 no-border">4.1 Property Listings</h2>
      <p>
        If you want to sell a property, you may list it with us. We will market
        your property to potential buyers through our website and other
        platforms. We do not guarantee that your property will be sold or that
        you will receive any offers.
      </p>
      <h2 className="h5 no-border">4.2 Offers and Contracts</h2>
      <p>
        If you receive an offer to purchase your property, we will present it to
        you and negotiate on your behalf. If you accept the offer, we will
        prepare a contract for the sale of the property. You are responsible for
        reviewing the contract and ensuring that it meets your requirements.
      </p>
      <h2 className="h5 no-border">4.3 Closing</h2>
      <p>
        Once the contract is signed, we will coordinate the closing of the sale.
        You are responsible for paying all closing costs, including but not
        limited to, title insurance, taxes, and fees.
      </p>
      <h2 className="h4">5. Renting Properties</h2>
      <h2 className="h5 no-border">5.1 Property Listings</h2>
      <p>
        We provide property listings on our website and other platforms. These
        listings are provided for informational purposes only and are subject to
        change without notice. We do not guarantee the accuracy or completeness
        of the listings.
      </p>
      <h2 className="h5 no-border">5.2 Rental Applications</h2>
      <p>
        If you are interested in renting a property, you may submit a rental
        application. We will review your application and may require additional
        information, such as a credit report or employment verification. We do
        not guarantee that your application will be approved or that you will be
        able to rent the property.
      </p>
      <h2 className="h5 no-border">5.3 Rental Agreements</h2>
      <p>
        If your rental application is approved, we will prepare a rental
        agreement for the property. You are responsible for reviewing the
        agreement and ensuring that it meets your requirements.
      </p>
      <h2 className="h4">6. Fees and Commissions</h2>
      <h2 className="h5 no-border">6.1 Fees</h2>
      <p>
        We may charge fees for our services, such as listing fees, transaction
        fees, and rental application fees. These fees are subject to change
        without notice.
      </p>
      <h2 className="h5 no-border">6.2 Commissions</h2>
      <p>
        If you buy, sell, or rent a property through our services, we may
        receive a commission from the transaction. The commission amount will be
        disclosed to you before the transaction is completed.
      </p>
      <h2 className="h4"> 7. Intellectual Property</h2>
      <p>
        All content on our website and other platforms, including but not
        limited to, text, graphics, logos, and images, is the property of Regale
        Homes or its licensors and is protected by copyright and other
        intellectual property laws. You may not use, reproduce, or distribute
        any content without our prior written consent.
      </p>
      <h2 className="h4"> 8. Disclaimer of Warranties</h2>
      <p>
        We provide our services "as is" and without any warranty or condition,
        express, implied, or statutory. We do not guarantee that our services
        will be uninterrupted or error-free. We do not guarantee the accuracy or
        completeness of any information provided through our services.
      </p>
      <h2 className="h4">9. Limitation of Liability</h2>
      <p>
        To the extent permitted by law, we will not be liable for any indirect,
        incidental, special, or consequential damages arising out of or in
        connection with the use of our services, including but not limited to,
        damages for loss of profits, goodwill, use, data, or other
        intangible losses.
      </p>
    </section>
  )
}

export default TermsOfService
