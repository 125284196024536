import React from 'react'

const Input = ({ name, label, id, ...rest }) => {
  return (
    <div className="form-register">
      <label htmlFor={id} className="form-label">
        {label}:
      </label>
      <input {...rest} name={name} className="form-control" />
    </div>
  )
}

export default Input
