import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Input from './common/Input'
import logo from '../images/logo2.png'
import api from '../config.json'

class Register extends Component {
  state = {
    data: {
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      password2: '',
    },
    errors: {},
    token: '',
  }

  componentDidMount() {
    const token = localStorage.getItem('token')
    if (token === null) {
      this.setState({ token })
    } else {
      this.props.history.push('/')
    }
  }

  handleChange = async ({ currentTarget: input }) => {
    const data = { ...this.state.data }
    data[input.name] = input.value
    this.setState({ data })
  }

  handleSubmit = async (e) => {
    e.preventDefault()

    const data = { ...this.state.data }
    const send = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      username: data.email,
      password: data.password,
      password2: data.password2,
    }
    const webApiUrl = api.api + '/register/'

    try {
      const { data: response } = await axios.post(webApiUrl, send)
      this.setState({ errors: response })
      this.props.history.push('../login')
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors }
        errors.email = ex.response.data.email
        errors.password = ex.response.data.password
        this.setState({ errors })
        console.log(errors.email, errors.password)
      }
    }
  }
  render() {
    const { errors } = this.state
    return (
      <React.Fragment>
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/" className="no-deco">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active">Create an Account</li>
            </ol>
          </nav>
        </div>
        <div className="container">
          <h1 className="h3 mb-3 fw-normal">Create an account</h1>
        </div>
        <div className="container">
          <main className="form-signup m-auto">
            <form onSubmit={this.handleSubmit}>
              <div className="text-center">
                <img
                  className="mb-4"
                  src={logo}
                  alt=""
                  width="100"
                  height="75"
                />
              </div>
              <div className="row">
                <Input
                  type="text"
                  name="first_name"
                  id="firstName"
                  value={this.state.data.first_name}
                  label="First Name"
                  onChange={this.handleChange}
                  placeholder="Joe"
                />
                <Input
                  type="text"
                  name="last_name"
                  id="lastName"
                  value={this.state.data.last_name}
                  label="Last Name"
                  onChange={this.handleChange}
                  placeholder="Smith"
                />
                <Input
                  type="email"
                  name="email"
                  id="email"
                  value={this.state.data.email}
                  label="Email Address"
                  onChange={this.handleChange}
                  placeholder="joe@regalhomes.com"
                />
                {errors.email ? (
                  <span>
                    <div
                      id="alert"
                      className="alert alert-danger alert-dismissible fade show"
                      role="alert"
                      key="1"
                    >
                      {errors.email}
                    </div>
                  </span>
                ) : null}
                <Input
                  type="password"
                  value={this.state.data.password}
                  name="password"
                  placeholder="Password"
                  id="password"
                  label="Password"
                  onChange={this.handleChange}
                />
                <Input
                  type="password"
                  value={this.state.password2}
                  name="password2"
                  placeholder="Password"
                  id="password2"
                  label="Confirm Password"
                  onChange={this.handleChange}
                />
              </div>
              {errors.password ? (
                <span>
                  <div
                    id="alert"
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                    key="1"
                  >
                    {errors.password}
                  </div>
                </span>
              ) : null}
              <button
                className="w-100 btn btn-lg btn-info text-white"
                type="submit"
              >
                Register
              </button>
              <p className="lead mt-2 text-center">Already have an account?</p>
              <div className="text-center">
                <Link to="/signin">
                  <button className="btn btn-primary btn-lg">Sign In</button>
                </Link>
              </div>

              <p className="mt-5 mb-3 text-muted">&copy; 2017...</p>
            </form>
          </main>
        </div>
      </React.Fragment>
    )
  }
}

export default Register
