import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

const Slider = ({ properties }) => {
  const newProperties = properties.slice(0, 3)
  return (
    <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>
      <div className="carousel-inner" id="carousel-inner">
        {newProperties.map((property) => (
          <div id="slider" className="carousel-item" key={property.id}>
            <img
              className="bd-placeholder-img carousel-bg-image"
              width="100%"
              height="100%"
              src={property.image_1}
              alt=""
              aria-hidden="true"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            />

            <div className="carousel-caption text-start text-white">
              <Link to={`/properties/${property.id}`}>
                <button className="btn btn-lg btn-primary">
                  ${property.price}
                </button>
              </Link>
              <h4 className="shadow">{property.name}</h4>
              <p className="lead text-white">{property.location}</p>
              <hr />
              <Link
                className="icon-link shadow"
                to={`/properties/${property.id}`}
              >
                Read more
                <FontAwesomeIcon icon={solid('chevron-right')} className="bi" />
              </Link>
            </div>
          </div>
        ))}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#myCarousel"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#myCarousel"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  )
}

export default Slider
