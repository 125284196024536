import React, { Component } from 'react'
import axios from 'axios'
import Slider from './Slider'
import Services from './Services'
import NewProperties from './NewProperties'
import { activeClass } from '../utils/activeClass'
import api from '../config.json'

// console.log(api.api)
class HomePage extends Component {
  state = {
    properties: [],
  }

  async componentDidMount() {
    // let webApiUrl = 'https://djangoreact.applikuapp.com/api/properties/'
    let webApiUrl = api.api + '/properties/'
    const { data: properties } = await axios.get(webApiUrl)
    this.setState({ properties })
    activeClass()
  }
  render() {
    const { properties } = this.state
    return (
      <React.Fragment>
        <Slider properties={properties} />
        <div className="bg-white">
          {/* <div className="hero"><p className="display-4">Hello</p></div> */}
          <Services />
          <NewProperties properties={properties} />
        </div>
      </React.Fragment>
    )
  }
}

export default HomePage
