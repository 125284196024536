import $ from 'jquery'

var imagePopup = $('.image-popup');
if (imagePopup.length > 0) {
    imagePopup.magnificPopup({
        type: 'image',
        removalDelay: 300,
        mainClass: 'mfp-fade',
        overflowY: 'scroll'
    });
}