import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Link } from 'react-router-dom'

class Services extends Component {
  state = {}
  render() {
    return (
      <section id="our-services" className="block">
        <div className="container">
          <header className="section-title m-3 mt-3 mb-3">
            <h2>Our Services</h2>
          </header>
          <div className="row">
            <div className="col-md-4 col-sm-4">
              <div className="feature-box equal-height">
                <FontAwesomeIcon icon={solid('folder')} className="fa" />
                <aside className="description">
                  <header>
                    <h3>Wide Range of Properties</h3>
                  </header>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                  <Link className="call-to-action link-arrow" to="">
                    Read more
                    <FontAwesomeIcon
                      icon={solid('chevron-right')}
                      className="bi"
                    />
                  </Link>
                </aside>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="feature-box equal-height">
                <FontAwesomeIcon icon={solid('users')} className="fa" />
                <aside className="description">
                  <header>
                    <h3>14 Agents for Your Service</h3>
                  </header>
                  <p>
                    Aliquam gravida magna et fringilla convallis. Pellentesque
                    habitant morbi
                  </p>
                  <Link className="call-to-action link-arrow" to="">
                    Read more
                    <FontAwesomeIcon
                      icon={solid('chevron-right')}
                      className="bi"
                    />
                  </Link>
                </aside>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="feature-box equal-height">
                <FontAwesomeIcon icon={solid('money-bill-1')} className="fa" />
                <aside className="description">
                  <header>
                    <h3>Best Price Guarantee!</h3>
                  </header>
                  <p>
                    Phasellus non viverra tortor, id auctor leo. Suspendisse id
                    nibh placerat
                  </p>
                  <Link className="call-to-action link-arrow" to="">
                    Read more
                    <FontAwesomeIcon
                      icon={solid('chevron-right')}
                      className="bi"
                    />
                  </Link>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Services
