import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import logo from '../images/logo2.png'
import api from '../config.json'

class SignIn extends Component {
  state = {
    data: {
      username: '',
      password: '',
      token: '',
    },
    errors: {
      non_field_errors: [],
    },
  }

  componentDidMount() {
    const token = localStorage.getItem('token')
    if (token === null) {
      this.setState({ token })
    } else {
      this.props.history.push('/')
    }
  }

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data }
    data[input.name] = input.value
    this.setState({ data })
  }

  handleSubmit = async (e) => {
    e.preventDefault()

    const data = { ...this.state.data }
    const webApiUrl = api.api + '/auth/'
    console.log(webApiUrl)

    try {
      const { data: response } = await axios.post(webApiUrl, data)
      localStorage.setItem('token', response.token)
      this.props.history.push('/')
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors }
        errors.non_field_errors = ex.response.data.non_field_errors
        this.setState({ errors })
      }
    }
  }

  render() {
    const { errors } = this.state
    return (
      <React.Fragment>
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/" className="no-deco">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active">Sign In</li>
            </ol>
          </nav>
        </div>
        <div className="container text-center">
          <main className="form-signin m-auto w-100">
            <form>
              <img className="mb-4" src={logo} alt="" width="100" height="75" />
              <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

              <div className="form-floating">
                <input
                  type="email"
                  name="username"
                  value={this.state.data.username}
                  className="form-control"
                  id="email"
                  placeholder="name@example.com"
                  onChange={this.handleChange}
                />
                <label htmlFor="floatingInput">Email address</label>
              </div>
              <div className="form-floating">
                <input
                  type="password"
                  value={this.state.data.password}
                  className="form-control"
                  name="password"
                  placeholder="Password"
                  onChange={this.handleChange}
                />
                <label htmlFor="floatingPassword">Password</label>
              </div>
              {errors
                ? errors.non_field_errors.map((error) => (
                    <div
                      id="alert"
                      className="alert alert-danger alert-dismissible fade show"
                      role="alert"
                      key="1"
                    >
                      {error}
                      {/* <button
                        type="button"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        className="btn-close"
                      ></button> */}
                    </div>
                  ))
                : ''}
              <button
                onClick={this.handleSubmit}
                className="w-100 btn btn-lg btn-primary"
                type="submit"
              >
                Sign in
              </button>
              <p className="lead mt-2">Don't have an account?</p>
              <div className="text-center">
                <Link to="/register">
                  <button className="btn btn-info btn-lg">Register</button>
                </Link>
                <p className="mt-5 mb-3 text-muted">&copy; 2017...</p>
              </div>
            </form>
          </main>
        </div>
      </React.Fragment>
    )
  }
}

export default SignIn
