import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import PrivacyPolicyText from './texts/privacyPolicy'
import PropertiesSide from './PropertiesSide'
import api from '../config.json'

class PrivacyPolicy extends Component {
  state = {
    properties: [],
  }

  async componentDidMount() {
    let webApiUrl = api.api + '/properties/'
    const { data: properties } = await axios.get(webApiUrl)
    this.setState({ properties })
  }

  render() {
    return (
      <div className="page-content">
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/" className="no-deco">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active">Privacy Policy</li>
            </ol>
          </nav>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-sm-9">
              <header>
                <h2>Privacy Policy</h2>
              </header>
              <PrivacyPolicyText />
            </div>
            <PropertiesSide properties={this.state.properties} />
          </div>
        </div>
      </div>
    )
  }
}

export default PrivacyPolicy
