import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import logo from '../images/logo3.png'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

class Contact extends Component {
  state = {}
  render() {
    return (
      <React.Fragment>
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/" className="no-deco">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active">Contact</li>
            </ol>
          </nav>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <header>
                <h2>Contact Us</h2>
              </header>
              <section id="contact" className="contact">
                <div className="container">
                  <div className="section-title">
                    <img
                      src={logo}
                      alt="Regale Homes"
                      width="200px"
                      className="logo float-right"
                    />
                    <p className="mt--3">
                      Are you tired of endlessly searching for the perfect home
                      ? Look no further than Regale Homes, your trusted partner
                      in the real estate industry.With years of experience and a
                      commitment to personalized service, we're here to help you
                      find your dream home
                    </p>
                    <p>Leave a message For Us now</p>
                  </div>

                  <div className="row">
                    <div className="col-lg-5 d-flex align-items-stretch">
                      <div className="contact info">
                        <a
                          href="mailto:info@regalehomes.com"
                          className="no-deco"
                        >
                          <div className="email d-flex mt-4">
                            <div className="m-2">
                              <FontAwesomeIcon
                                icon={regular('envelope')}
                                className="contact-detail-icon"
                              />
                            </div>
                            <div>
                              <h4>Email:</h4>
                              <p>info@regalehomes.com</p>
                            </div>
                          </div>
                        </a>
                        <a href="phone:+17918387264" className="no-deco">
                          <div className="phone d-flex">
                            <div className="m-2">
                              <FontAwesomeIcon
                                icon={regular('user')}
                                className="contact-detail-icon"
                              />
                            </div>
                            <div>
                              <h4>Call:</h4>
                              <p>+1 (791) 8387 264</p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>

                    <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                      <form className="react-form">
                        <div className="row">
                          <div className="form-group col-6">
                            <label for="name">Your Name:</label>
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              id="name"
                              required
                            />
                          </div>
                          <div className="form-group col-6">
                            <label for="name">Your Email:</label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              id="email"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label for="name">Subject:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="subject"
                            id="subject"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label for="name">Message:</label>
                          <textarea
                            className="form-control"
                            name="message"
                            rows="10"
                            required
                          ></textarea>
                        </div>
                        <div className="float-right">
                          <button
                            type="submit"
                            className="btn btn-success mt-2"
                          >
                            Send Message
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Contact
