import React, { Component } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import api from '../config.json'

class Footer extends Component {
  state = {
    properties: [],
    loggedIn: false,
  }

  async componentDidMount() {
    let webApiUrl = api.api + '/properties/'
    const { data: allProperties } = await axios.get(webApiUrl)
    const properties = allProperties.slice(0, 2)
    this.setState({ properties })
    const token = localStorage.getItem('token')
    if (token === null) {
      this.setState({ loggedIn: false })
    } else {
      this.setState({ loggedIn: true })
    }
  }
  render() {
    const properties = this.state.properties
    return (
      <React.Fragment>
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <h5 className="mt-4 mb-4">About Us</h5>
                <p className="footer-text">
                  Are you tired of endlessly searching for the perfect home ?
                  Look no further than Regale Homes, your trusted partner in the
                  real estate industry.With years of experience and a commitment
                  to personalized service, we're here to help you find your
                  dream home
                </p>
                <hr />
                <Link className="call-to-action footer-text" to="/about-us">
                  Read more
                  <FontAwesomeIcon
                    icon={solid('chevron-right')}
                    className="bi"
                  />
                </Link>
              </div>
              <div className="col-md-3">
                <h5 className="mt-4 mb-4">Recent Properties</h5>
                {properties.map((property) => (
                  <div className="small d-flex" key={property.id}>
                    <Link to={`/properties/${property.id}`}>
                      <div className="property-image">
                        <img alt="" src={property.image_3} />
                      </div>
                    </Link>
                    <div className="info">
                      <Link
                        to={`/properties/${property.id}`}
                        className="no-deco"
                      >
                        <h6>{property.name}</h6>
                      </Link>
                      <figure>
                        {property.city}, {property.district}
                      </figure>
                      <p className="tag price">${property.price}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-md-3">
                <div className="footer-contact">
                  <h5 className="mt-4 mb-4">Contact</h5>
                  <address className="footer-text">
                    <strong>Ragale Homes</strong>
                    <br />
                    4877 Spruce Drive
                    <br />
                    West Newton, PA 15089
                  </address>
                  <p className="footer-text">
                    +1 (734) 123-4567 <br />
                    <a href="mailto:info@regalehomes.com" className="no-deco">
                      info@regalehomes.com
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <article className="useful-links-cover">
                  <h5 className="mt-4 mb-4">Useful Links</h5>
                  <ul className="footer-text">
                    <li>
                      <Link to="" className="no-deco useful-link">
                        All Properties
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/privacy-policy"
                        className="no-deco useful-link"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    {this.state.loggedIn ? null : (
                      <li>
                        <Link to="/register" className="no-deco useful-link">
                          Login and Register Account
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link to="" className="no-deco useful-link">
                        FAQ
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/terms-and-conditions"
                        className="no-deco useful-link"
                      >
                        Terms and Conditions
                      </Link>
                    </li>
                  </ul>
                </article>
              </div>
            </div>
          </div>
          <div className="blue-background">.</div>
        </footer>
      </React.Fragment>
    )
  }
}

export default Footer
